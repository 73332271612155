import {onDOMReady} from './dom';

onDOMReady(() => {
    const navigateButton = document.getElementById('mlpartsFooter__storeInBrno__navigateButton');

    if (navigateButton) {
        navigateButton.addEventListener('click', (event) => {
            event.preventDefault();
            let href = navigateButton.getAttribute('href');

            if ((navigator.platform.indexOf('iPhone') !== -1) || (navigator.platform.indexOf('iPod') !== -1) || (navigator.platform.indexOf('iPad') !== -1)) {
                href = href.replace('https://', 'maps://');
            }

            window.open(href);
        });
    }
});