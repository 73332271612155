import {onDOMReady} from './dom';

const QUERY_SELECTOR_SEARCH_OPENER = '.mlpartsHeader__navItem.--searchOpener';
const CLASS_SEARCH_BAR = 'mlpartsHeader__searchBar';
const CLASS_CART_BADGE = 'mlpartsHeader__navItemBadge';
const ID_LAYOUT_HEADER = 'layoutHeader';
const CLASS_ACTIVE = '--active';

onDOMReady(() => {
    const searchOpener = document.querySelector(QUERY_SELECTOR_SEARCH_OPENER);
    const searchBar = document.getElementsByClassName(CLASS_SEARCH_BAR)[0];
    const searchBarInput = searchBar.getElementsByTagName('input')[0];
    const layoutHeader = document.getElementById(ID_LAYOUT_HEADER);

    const searchClose = () => {
        layoutHeader.classList.remove(CLASS_ACTIVE);
        searchBar.classList.remove(CLASS_ACTIVE);
    };

    const searchOpen = () => {
        layoutHeader.classList.add(CLASS_ACTIVE);
        layoutHeader.classList.add(CLASS_ACTIVE);
        searchBar.classList.add(CLASS_ACTIVE);
        searchBarInput.focus();
    };

    document.addEventListener('dropdownClose', (event) => {
        if (event.detail.dropdownElement.id === searchBarInput.dataset.dropdownId) {
            searchClose();
        }
    });

    searchOpener.addEventListener('click', () => {
        if (searchBar.classList.contains(CLASS_ACTIVE)) {
            searchClose();
        } else {
            searchOpen();
        }
    });

    const renderCartBadge = () => {
        const cartBadge = document.getElementsByClassName(CLASS_CART_BADGE)[0];
        const count = window.KMPayload?.cart?.count;
        cartBadge.hidden = !count;
        cartBadge.textContent = count;
        console.log('mlpartsHeader.renderCartBadge', window.KMPayload?.cart?.count, count, cartBadge);
    };

    renderCartBadge();
    document.addEventListener('kvik_ajax_payload', () => renderCartBadge());
});